// import Swiper JS
import Swiper, { Autoplay, Lazy, EffectFade } from 'swiper';

const swiperOptions = {
  slidesPerView: 1,
  effect: 'fade',
  fadeEffect: {
    crossFade: true
  },
  centeredSlides: true,
  loop: true,
  grabCursor: false,
  speed: 500,
  autoplay: {
    delay: 4000,
    disableOnInteraction: false,
  },
  allowTouchMove: false,
  lazy: {
    loadPrevNext: true,
  },
  watchSlidesVisibility: true,
}

export const landscapeSlider = () => {
  Swiper.use([Autoplay, Lazy, EffectFade]);
  swiperOptions.autoplay.delay = 2750;
  var mySwiper = new Swiper('.swiper-container.landscape-swiper', swiperOptions);

  return mySwiper;
}

export const portraitSlider = () => {
  Swiper.use([Autoplay, Lazy, EffectFade]);
  swiperOptions.autoplay.delay = 3750;
  var mySwiper = new Swiper('.swiper-container.portrait-swiper', swiperOptions);

  return mySwiper;
}

export const squareSlider = () => {
  Swiper.use([Autoplay, Lazy, EffectFade]);
  swiperOptions.autoplay.delay = 4350;
  var mySwiper = new Swiper('.swiper-container.square-swiper', swiperOptions);

  return mySwiper;
}